// Swiper 5.3.6
import './vendor/swiper';
import './vendor/body-scroll-lock';
// SmoothScroll for websites v1.4.10 (Balazs Galambosi)
import './vendor/smooth-scroll';
import './vendor/focus-visible';
import './vendor/splitting';

import './vendor/gsap/gsap.min';
import './vendor/gsap/scroll-to-plugin.min';

import './vendor/gsap/scroll-trigger.min';
